/* eslint-disable */
// @ts-nocheck
/*
 * This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
 */

import * as fm from '@generated/fetch.pb';
export type V2GetTeacherPythagorasIdentityTokenRequest = {
    email?: string;
};

export type V2GetTeacherPythagorasIdentityTokenResponse = {
    token?: string;
};

export class PythagorasV2 {
    static GetV2PythagorasIdentityToken(
        req: V2GetTeacherPythagorasIdentityTokenRequest,
        initReq?: fm.InitReq
    ): Promise<V2GetTeacherPythagorasIdentityTokenResponse> {
        return fm.fetchReq<
            V2GetTeacherPythagorasIdentityTokenRequest,
            V2GetTeacherPythagorasIdentityTokenResponse
        >(`/v2/pythagoras/identityToken?${fm.renderURLSearchParams(req, [])}`, {
            ...initReq,
            method: 'GET',
        });
    }
}
